import { PRIMARY_COLOR } from "./colors";

export const CAPTION_FONT_SIZE = "0.75rem"; // 12px
export const CAPTION_LINE_HEIGHT = 1.21; // 14.5px

export const BODY_2_FONT_SIZE = "0.875rem"; // 14px
export const BODY_2_LINE_HEIGHT = 1.21; // 17px

export const BODY_1_FONT_SIZE = "1rem"; // 16px
export const BODY_1_LINE_HEIGHT = 1.06; // 17px

export const H5_FONT_SIZE = "1.25rem"; // 20px
export const H5_LINE_HEIGHT = 1.2; // 24px

export const H4_FONT_SIZE = "1.5rem"; // 24px
export const H4_LINE_HEIGHT = 1.21; // 29px

export const H3_FONT_SIZE = "2rem"; // 32px
export const H3_LINE_HEIGHT = 1.09; // 35px

export const H2_FONT_SIZE = "3rem"; // 48px
export const H2_LINE_HEIGHT = 1.1; // 52.8px

export const H1_FONT_SIZE = "4rem"; // 64px
export const H1_LINE_HEIGHT = 1.2; // 76.8px

export const muiThemeTypography = {
  allVariants: {
    fontFamily:
      '"SF Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif',
    color: PRIMARY_COLOR,
    fontWeight: 400,
    lineHeight: "inherit",
  },
  h1: {
    fontSize: H1_FONT_SIZE,
    lineHeight: H1_LINE_HEIGHT,
  },
  h2: {
    fontSize: H2_FONT_SIZE,
    lineHeight: H2_LINE_HEIGHT,
  },
  h3: {
    fontSize: H3_FONT_SIZE,
    lineHeight: H3_LINE_HEIGHT,
  },
  h4: {
    fontSize: H4_FONT_SIZE,
    lineHeight: H4_LINE_HEIGHT,
  },
  h5: {
    fontSize: H5_FONT_SIZE,
    lineHeight: H5_LINE_HEIGHT,
  },
  body1: {
    fontSize: BODY_1_FONT_SIZE,
    lineHeight: BODY_1_LINE_HEIGHT,
  },
  body2: {
    fontSize: BODY_2_FONT_SIZE,
    lineHeight: BODY_2_LINE_HEIGHT,
  },
  caption: {
    fontSize: CAPTION_FONT_SIZE,
    lineHeight: CAPTION_LINE_HEIGHT,
  },
};
