/* eslint-disable max-lines */
import { type PaletteOptions } from "@mui/material";

/**
 * !! DO NOT IMPORT THESE COLORS DIRECTLY !!
 * Always use MUI Theme for colors and if any of these is not in the palette, add it
 */
export const WARM_GREY_00_COLOR = "#FFFFFF";
export const WARM_GREY_01_COLOR = "#F6F3F1";
export const WARM_GREY_02_COLOR = "#F1EDEA";
export const WARM_GREY_03_COLOR = "#E6E2DD";
export const WARM_GREY_04_COLOR = "#D5D1CE";
export const WARM_GREY_05_COLOR = "#B0AEAC";
export const WARM_GREY_06_COLOR = "#8B8987";
export const WARM_GREY_07_COLOR = "#3D414B";

export const COOL_GREY_01_COLOR = "#E9ECF2";
export const COOL_GREY_02_COLOR = "#D7DEE8";
export const COOL_GREY_03_COLOR = "#B4BECD";
export const COOL_GREY_04_COLOR = "#9CA9BD";
export const COOL_GREY_05_COLOR = "#7689A6";
export const COOL_GREY_06_COLOR = "#3B4C65";
export const COOL_GREY_07_COLOR = "#2F3C50";

export const BLUE_01_COLOR = "#E8F4FF";
export const BLUE_02_COLOR = "#CCE7FF";
export const BLUE_03_COLOR = "#B7DDFF";
export const BLUE_04_COLOR = "#98CCFB";
export const BLUE_05_COLOR = "#83AFEC";
export const BLUE_06_COLOR = "#0D2B57";
export const BLUE_07_COLOR = "#0C1C43";

export const PURPLE_01_COLOR = "#ECEBFF";
export const PURPLE_02_COLOR = "#D7D4FF";
export const PURPLE_03_COLOR = "#BDB8FE";
export const PURPLE_04_COLOR = "#9890FF";
export const PURPLE_05_COLOR = "#7269F0";
export const PURPLE_06_COLOR = "#5851C6";
export const PURPLE_07_COLOR = "#372FAA";

export const TEAL_01_COLOR = "#E1F5F6";
export const TEAL_02_COLOR = "#C9F2F4";
export const TEAL_03_COLOR = "#AEEDF1";
export const TEAL_04_COLOR = "#75D1D6";
export const TEAL_05_COLOR = "#45C1C8";
export const TEAL_06_COLOR = "#03929A";
export const TEAL_07_COLOR = "#004345";

export const GREEN_01_COLOR = "#E0FBEF";
export const GREEN_02_COLOR = "#C0F6D4";
export const GREEN_03_COLOR = "#ADF3C7";
export const GREEN_04_COLOR = "#75DEAF";
export const GREEN_05_COLOR = "#43C390";
export const GREEN_06_COLOR = "#169563";
export const GREEN_07_COLOR = "#094534";

export const YELLOW_01_COLOR = "#FFFBF6";
export const YELLOW_02_COLOR = "#FFF3E6";
export const YELLOW_03_COLOR = "#FFE7BC";
export const YELLOW_04_COLOR = "#FACE97";
export const YELLOW_05_COLOR = "#FFC884";
export const YELLOW_06_COLOR = "#BD6801";
export const YELLOW_07_COLOR = "#764100";

export const RED_01_COLOR = "#FCF2F0";
export const RED_02_COLOR = "#F9E1E1";
export const RED_03_COLOR = "#F7D0D0";
export const RED_04_COLOR = "#FCBDC1";
export const RED_05_COLOR = "#DB646A";
export const RED_06_COLOR = "#B01921";
export const RED_07_COLOR = "#83090F";

export const PRIMARY_COLOR = BLUE_07_COLOR;
export const SECONDARY_COLOR = WARM_GREY_01_COLOR;

export const DISABLED_PRIMARY_COLOR = WARM_GREY_06_COLOR;
export const DISABLED_SECONDARY_COLOR = WARM_GREY_02_COLOR;

export const BACKGROUND_PRIMARY_COLOR = WARM_GREY_01_COLOR;
export const BACKGROUND_SECONDARY_COLOR = WARM_GREY_02_COLOR;
export const BACKGROUND_TERTIARY_COLOR = WARM_GREY_00_COLOR;
export const BACKGROUND_INVERT_COLOR = PRIMARY_COLOR;

export const TEXT_PRIMARY_COLOR = PRIMARY_COLOR;
export const TEXT_SECONDARY_COLOR = WARM_GREY_07_COLOR;
export const TEXT_TERTIARY_COLOR = WARM_GREY_06_COLOR;
export const TEXT_DISABLED_COLOR = WARM_GREY_05_COLOR;
export const TEXT_INVERT_PRIMARY_COLOR = WARM_GREY_00_COLOR;
export const TEXT_INVERT_SECONDARY_COLOR = COOL_GREY_03_COLOR;
export const TEXT_INVERT_TERTIARY_COLOR = COOL_GREY_04_COLOR;

export const SURFACE_PRIMARY_COLOR = PRIMARY_COLOR;
export const SURFACE_SECONDARY_COLOR = WARM_GREY_00_COLOR;
export const SURFACE_SHADED_COLOR = WARM_GREY_01_COLOR;
export const SURFACE_SHADED_DARK_COLOR = WARM_GREY_03_COLOR;

export const BORDER_PRIMARY_COLOR = WARM_GREY_04_COLOR;
export const BORDER_SUBTLE_COLOR = WARM_GREY_01_COLOR;
export const BORDER_DARK_COLOR = WARM_GREY_05_COLOR;
export const BORDER_PRIMARY_INVERT_COLOR = COOL_GREY_06_COLOR;
export const BORDER_SUBTLE_INVERT_COLOR = COOL_GREY_07_COLOR;
export const BORDER_DARK_INVERT_COLOR = COOL_GREY_03_COLOR;

export const NEUTRAL_CALLOUT_LABEL_COLOR = TEAL_03_COLOR;
export const NEUTRAL_CALLOUT_TEXT_COLOR = TEAL_07_COLOR;

export const SUCCESS_CALLOUT_LABEL_COLOR = GREEN_03_COLOR;
export const SUCCESS_CALLOUT_TEXT_COLOR = GREEN_07_COLOR;

export const ERROR_CALLOUT_LABEL_COLOR = RED_02_COLOR;
export const ERROR_CALLOUT_BACKGROUND_COLOR = RED_03_COLOR;
export const ERROR_CALLOUT_TEXT_COLOR = RED_06_COLOR;

export const SHIFT_GENERAL_COLOR = BLUE_03_COLOR;
export const SHIFT_DAY_COLOR = BLUE_02_COLOR;
export const SHIFT_EVENING_COLOR = BLUE_04_COLOR;
export const SHIFT_OVERNIGHT_COLOR = BLUE_05_COLOR;

export const SHIFT_PRIORITY_LABEL_COLOR = PURPLE_02_COLOR;
export const SHIFT_PRIORITY_BACKGROUND_COLOR = PURPLE_03_COLOR;
export const SHIFT_PRIORITY_TEXT_COLOR = PURPLE_07_COLOR;

export const SHIFT_URGENT_LABEL_COLOR = YELLOW_04_COLOR;
export const SHIFT_URGENT_TEXT_COLOR = YELLOW_07_COLOR;

export const SHIFT_GENERAL_GRADIENT = "linear-gradient(180deg, #B7DDFF 19.5%, #F6F3F1 100%)";
export const SHIFT_DAY_GRADIENT = "linear-gradient(174.67deg, #D3EAFF 5.15%, #F6F3F1 95.73%)";
export const SHIFT_EVENING_GRADIENT = "linear-gradient(180deg, #98CCFB 19.5%, #F7F0EA 100%)";
export const SHIFT_OVERNIGHT_GRADIENT = "linear-gradient(171.85deg, #83AFEC 20.55%, #F6F3F1 89.4%)";

export const SHIFT_PRIORITY_GRADIENT = "linear-gradient(349.66deg, #F6F3F1 53.34%, #BDB8FE 93.29%)";
export const SHIFT_URGENT_GRADIENT = "linear-gradient(166.67deg, #FFE3C3 5.67%, #F9F5F2 49.8%)";

export const UNREAD_CHAT_NOTIFICATION_BADGE_COLOR = BLUE_05_COLOR;

declare module "@mui/material/styles" {
  // So far these are optional as module augmentation will affect the old theme and I want to avoid confusions
  interface CustomPalette {
    surface?: {
      primary: string;
      secondary: string;
      shaded?: string;
      shadedDark?: string;
    };
    disabled?: {
      primary?: string;
      secondary?: string;
    };
    border?: {
      primary?: string;
      dark?: string;
      subtle?: string;
      primaryInvert?: string;
      subtleInvert?: string;
    };
    callout?: {
      neutral: {
        label: string;
        text: string;
      };
      success: {
        label: string;
        text: string;
      };
      error: {
        label: string;
        background: string;
        text: string;
      };
    };
    // This is intentionally exported separately so in the future we have the possibility to split base design system from hcp app.
    // If we were to continue using this design system for other apps, we could easily maintain a global one with generic colors
    // and only export HCP and shifts specific stuff separately.
    shifts?: {
      urgent: {
        label: string;
        text: string;
        gradient: string;
      };
      priority: {
        label: string;
        background: string;
        text: string;
        gradient: string;
      };
      general: {
        background: string;
        gradient: string;
      };
      day: {
        background: string;
        gradient: string;
      };
      evening: {
        background: string;
        gradient: string;
      };
      overnight: {
        background: string;
        gradient: string;
      };
    };
    // this is a temporary solution until we implement uniform nav bar
    customBackground?: {
      statusBar: string;
    };
  }

  // Actual MUI types
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary?: string;
    invertPrimary?: string;
    invertSecondary?: string;
    invertTertiary?: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    primary: string;
    secondary: string;
    tertiary?: string;
    invert?: string;
  }
}

export const muiThemePalette: PaletteOptions = {
  // MUI by default handles e.g pressed/hover states by applying a darker shade of the color/
  // In our designs we want to have a lighter shade for pressed/hover states, so we need to swap
  // dark and light
  primary: {
    main: PRIMARY_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
    dark: BLUE_06_COLOR,
    light: PRIMARY_COLOR,
  },
  // TODO: This needs some love but will be tackled here:
  // https://linear.app/clipboardhealth/issue/SPRTA-844/update-button-component
  secondary: {
    main: SECONDARY_COLOR,
    contrastText: PRIMARY_COLOR,
    // dark: GREY_02_COLOR,
    // light: GREY_01_COLOR,
  },
  error: {
    main: RED_06_COLOR,
    light: RED_07_COLOR,
    dark: RED_05_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  info: {
    main: BLUE_05_COLOR,
    light: BLUE_03_COLOR,
    dark: BLUE_04_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  warning: {
    main: YELLOW_04_COLOR,
    light: YELLOW_01_COLOR,
    dark: YELLOW_02_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  success: {
    main: GREEN_06_COLOR,
    light: GREEN_04_COLOR,
    dark: GREEN_05_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  disabled: {
    primary: DISABLED_PRIMARY_COLOR,
    secondary: DISABLED_SECONDARY_COLOR,
  },
  background: {
    default: BACKGROUND_PRIMARY_COLOR,
    paper: BACKGROUND_PRIMARY_COLOR,
    primary: BACKGROUND_PRIMARY_COLOR,
    secondary: BACKGROUND_SECONDARY_COLOR,
    tertiary: BACKGROUND_TERTIARY_COLOR,
    invert: BACKGROUND_INVERT_COLOR,
  },
  text: {
    primary: TEXT_PRIMARY_COLOR,
    secondary: TEXT_SECONDARY_COLOR,
  },
  action: {
    active: PRIMARY_COLOR,
  },
  border: {
    primary: BORDER_PRIMARY_COLOR,
    dark: BORDER_DARK_COLOR,
    subtle: BORDER_SUBTLE_COLOR,
    primaryInvert: BORDER_PRIMARY_INVERT_COLOR,
    subtleInvert: BORDER_SUBTLE_INVERT_COLOR,
  },
  customBackground: {
    statusBar: BACKGROUND_PRIMARY_COLOR,
  },
  surface: {
    primary: SURFACE_PRIMARY_COLOR,
    secondary: SURFACE_SECONDARY_COLOR,
    shaded: SURFACE_SHADED_COLOR,
    shadedDark: SURFACE_SHADED_DARK_COLOR,
  },
  callout: {
    neutral: {
      label: NEUTRAL_CALLOUT_LABEL_COLOR,
      text: NEUTRAL_CALLOUT_TEXT_COLOR,
    },
    success: {
      label: SUCCESS_CALLOUT_LABEL_COLOR,
      text: SUCCESS_CALLOUT_TEXT_COLOR,
    },
    error: {
      label: ERROR_CALLOUT_LABEL_COLOR,
      background: ERROR_CALLOUT_BACKGROUND_COLOR,
      text: ERROR_CALLOUT_TEXT_COLOR,
    },
  },
  shifts: {
    urgent: {
      label: SHIFT_URGENT_LABEL_COLOR,
      text: SHIFT_URGENT_TEXT_COLOR,
      gradient: SHIFT_URGENT_GRADIENT,
    },
    priority: {
      label: SHIFT_PRIORITY_LABEL_COLOR,
      background: SHIFT_PRIORITY_BACKGROUND_COLOR,
      text: SHIFT_PRIORITY_TEXT_COLOR,
      gradient: SHIFT_PRIORITY_GRADIENT,
    },
    day: {
      background: SHIFT_DAY_COLOR,
      gradient: SHIFT_DAY_GRADIENT,
    },
    general: {
      background: SHIFT_GENERAL_COLOR,
      gradient: SHIFT_GENERAL_GRADIENT,
    },
    evening: {
      background: SHIFT_EVENING_COLOR,
      gradient: SHIFT_EVENING_GRADIENT,
    },
    overnight: {
      background: SHIFT_OVERNIGHT_COLOR,
      gradient: SHIFT_OVERNIGHT_GRADIENT,
    },
  },
};
/* eslint-enable max-lines */
