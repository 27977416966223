/* eslint-disable max-lines */
import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_PRIMARY_COLOR,
  BACKGROUND_SECONDARY_COLOR,
  BACKGROUND_TERTIARY_COLOR,
  BORDER_PRIMARY_COLOR,
  BORDER_SUBTLE_COLOR,
  DISABLED_PRIMARY_COLOR,
  DISABLED_SECONDARY_COLOR,
  PRIMARY_COLOR,
  SURFACE_SECONDARY_COLOR,
  SURFACE_SHADED_DARK_COLOR,
  TEXT_DISABLED_COLOR,
  TEXT_INVERT_PRIMARY_COLOR,
  TEXT_PRIMARY_COLOR,
  TEXT_TERTIARY_COLOR,
  UNREAD_CHAT_NOTIFICATION_BADGE_COLOR,
} from "./colors";
import {
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_MEDIUM,
  BORDER_RADIUS_SMALL,
  BORDER_RADIUS_XLARGE,
  ELEVATION_1,
  ELEVATION_2,
  ELEVATION_3,
  SPACINGS,
} from "./common";
import {
  BODY_1_FONT_SIZE,
  BODY_2_FONT_SIZE,
  BODY_2_LINE_HEIGHT,
  H5_FONT_SIZE,
  H5_LINE_HEIGHT,
} from "./typography";

export const BUTTON_SIZE_EXTRA_SMALL = "1.25rem";
export const BUTTON_SIZE_SMALL = "2.5rem";
export const BUTTON_SIZE_MEDIUM = "3rem";
export const BUTTON_SIZE_LARGE = "4rem";

export const ICON_SIZE_EXTRA_SMALL = "0.875rem";
export const ICON_SIZE_SMALL = "1rem";
export const ICON_SIZE_MEDIUM = "1.5rem";
export const ICON_SIZE_LARGE = "2rem";
export const ICON_SIZE_EXTRA_LARGE = "3.5rem";

export const BORDER_WIDTH_THIN = "0.5px";
export const BORDER_WIDTH_REGULAR = "1px";

export const AVATAR_SIZE_SMALL = "2rem";
export const AVATAR_SIZE_MEDIUM = "4rem";
export const AVATAR_SIZE_LARGE = "6.5rem";

export const AVATAR_SIZES = ["small", "medium", "large"] as const;
export type AvatarSize = (typeof AVATAR_SIZES)[number];

export const INPUT_SIZES = ["small", "medium", "large"] as const;
export type InputSize = (typeof INPUT_SIZES)[number];

export const INPUT_SIZE_SMALL = "2.5rem"; // 40px
export const INPUT_SIZE_MEDIUM = "3rem"; // 48px
export const INPUT_SIZE_LARGE = "4rem"; // 64px

export const DATE_CELL_SMALL_WIDTH = "2.875rem"; // 46px
export const DATE_CELL_SMALL_HEIGHT = "4rem"; // 64px
export const DATE_CELL_MEDIUM_WIDTH = "4rem"; // 64px
export const DATE_CELL_MEDIUM_HEIGHT = "4.875rem"; // 78px

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

declare module "@mui/material/Card" {
  interface CardOwnProps {
    outlined?: boolean;
  }

  interface CardPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

declare module "@mui/material/Avatar" {
  interface AvatarOwnProps {
    size?: AvatarSize;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

declare module "@mui/material/Slider" {
  interface SliderOwnProps {
    variant?: "circular" | "rectangular";
    thumb?: boolean;
  }
}

export const muiThemeComponents: Components = {
  MuiAvatar: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      root: {
        backgroundColor: BACKGROUND_PRIMARY_COLOR,

        variants: [
          {
            props: { size: "small" },
            style: {
              width: AVATAR_SIZE_SMALL,
              height: AVATAR_SIZE_SMALL,
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: AVATAR_SIZE_MEDIUM,
              height: AVATAR_SIZE_MEDIUM,
            },
          },
          {
            props: { size: "large" },
            style: {
              width: AVATAR_SIZE_LARGE,
              height: AVATAR_SIZE_LARGE,
            },
          },
        ],
      },

      // Displayed when there is no image
      colorDefault: {
        backgroundColor: SURFACE_SHADED_DARK_COLOR,
        color: DISABLED_PRIMARY_COLOR,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      draggable: false,
    },
    styleOverrides: {
      root: {
        borderRadius: 100,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "unset",
        lineHeight: "1.5rem",
        borderRadius: BORDER_RADIUS_XLARGE,
        fontWeight: 500,

        // for some reason these icons have negative margin by default, we need to override it
        "& .MuiButton-startIcon": {
          marginLeft: 0,
        },

        "& .MuiButton-endIcon": {
          marginRight: 0,
        },

        // we need to override icon size here because they have different size in different buttons
        // which unfortunately don't match CbhIcon sizes...
        "& .MuiButton-startIcon, & .MuiButton-endIcon": {
          "& svg": {
            width: "1.25rem",
            height: "1.25rem",
          },
        },
      },
      sizeSmall: {
        fontWeight: 400,
        fontSize: BODY_2_FONT_SIZE,
        padding: "0.5rem 1.25rem", // 8px 20px
        height: BUTTON_SIZE_SMALL,

        "& .MuiButton-startIcon, & .MuiButton-endIcon": {
          "& svg": {
            width: "1rem",
            height: "1rem",
          },
        },
      },
      sizeMedium: {
        padding: "0.75rem 1.25rem", // 12px 20px
        fontSize: BODY_1_FONT_SIZE,
        height: BUTTON_SIZE_MEDIUM,
      },
      sizeLarge: {
        padding: "1.25rem 1.5rem", // 20px 24px
        fontSize: BODY_1_FONT_SIZE,
        height: BUTTON_SIZE_LARGE,
      },
      contained: {
        "&.Mui-disabled": {
          color: DISABLED_PRIMARY_COLOR,
          backgroundColor: DISABLED_SECONDARY_COLOR,
        },
      },
      outlined: {
        border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        backgroundColor: SURFACE_SECONDARY_COLOR,

        "&.Mui-disabled": {
          border: `1px solid ${DISABLED_PRIMARY_COLOR}`,
          backgroundColor: BACKGROUND_PRIMARY_COLOR,
        },

        "&:hover": {
          backgroundColor: SURFACE_SECONDARY_COLOR,
        },
      },
      outlinedSecondary: {
        color: PRIMARY_COLOR,

        "&:hover": {
          border: `1px solid ${PRIMARY_COLOR}`,
        },
      },
    },
  },
  // We are using custom CBHIcon component, so we need to override padding to 0
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    variants: [
      {
        props: { variant: "primary" },
        style: {
          backgroundColor: BACKGROUND_PRIMARY_COLOR,
        },
      },
      {
        props: { variant: "secondary" },
        style: {
          backgroundColor: BACKGROUND_SECONDARY_COLOR,
        },
      },
      {
        props: { variant: "tertiary" },
        style: {
          backgroundColor: BACKGROUND_TERTIARY_COLOR,
        },
      },
      {
        props: { elevation: 0 },
        style: {
          boxShadow: "none",
        },
      },
      {
        props: { elevation: 1 },
        style: {
          boxShadow: ELEVATION_1,
        },
      },
      {
        props: { elevation: 2 },
        style: {
          boxShadow: ELEVATION_2,
        },
      },
      {
        props: { elevation: 3 },
        style: {
          boxShadow: ELEVATION_3,
        },
      },
    ],
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
      variant: "tertiary",
    },
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS_MEDIUM,
      },
    },
    variants: [
      {
        props: { outlined: true },
        style: {
          border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        },
      },
    ],
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "1rem",

        "&:last-child": {
          paddingBottom: "1rem",
        },
      },
    },
  },
  MuiSlider: {
    defaultProps: {
      variant: "circular",
      thumb: false,
    },
    styleOverrides: {
      root: {
        // TBD, but we don't have guidelines on larger slider in design spec
        height: "0.5rem",
        padding: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.5rem",
        },

        variants: [
          {
            props: { variant: "rectangular" },
            style: {
              borderRadius: 0,
            },
          },
        ],
      },
      rail: {
        color: BORDER_PRIMARY_COLOR,
      },
      track: {
        border: "none",
      },
      mark: {
        display: "none",
      },
      markLabel: {
        marginTop: "1rem",
        color: TEXT_TERTIARY_COLOR,
        fontWeight: 500,

        "&.MuiSlider-markLabelActive": {
          color: TEXT_PRIMARY_COLOR,
        },

        // Align first mark label with the slider.
        // By default, transform is translateX(-50%).
        "&:nth-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(0)",
        },
        // Align last mark label with the slider.
        "&:nth-last-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(-100%)",
        },
      },
      thumb: {
        variants: [
          {
            props: { thumb: false },
            style: { display: "none" },
          },
          {
            props: { thumb: true },
            style: {
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 1px",
              },
              "&.Mui-active": {
                boxShadow: "0px 0px 0px 2px",
              },
            },
          },
          {
            props: { variant: "rectangular", thumb: true },
            style: {
              width: "2px",
              height: "calc(100% + 4px)",
              borderRadius: 0,
            },
          },
        ],
      },
      sizeSmall: {
        height: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.25rem",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        width: "fit-content",
        borderRadius: BORDER_RADIUS_LARGE,
        border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        padding: "0.25rem",
        height: "3rem",
      },
      indicator: {
        height: "100%",
        borderRadius: BORDER_RADIUS_XLARGE,
        // to show it below button content
        zIndex: 1,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        // to show it above the tab indicator
        zIndex: 2,
        textTransform: "none",
        padding: "0.75rem 1rem",
        flexDirection: "row",
        lineHeight: "1rem",
        minHeight: "2.5rem",
        whiteSpace: "nowrap",

        "&.Mui-selected": {
          color: TEXT_INVERT_PRIMARY_COLOR,
        },

        "&.MuiButtonBase-root": {
          transition: "color 0.3s ease-in-out",
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: "medium",
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        fontSize: BODY_2_FONT_SIZE,
        letterSpacing: "-0.01em",
        color: TEXT_PRIMARY_COLOR,

        input: {
          boxSizing: "border-box",

          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: "0.5rem", // 8px
          },

          "&::placeholder": {
            "-webkit-text-fill-color": TEXT_TERTIARY_COLOR,
            opacity: 1,
          },

          "&.Mui-disabled::placeholder": {
            "-webkit-text-fill-color": TEXT_DISABLED_COLOR,
          },
        },

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: BORDER_PRIMARY_COLOR,
        },

        "&.Mui-focused": {
          "&:not(.MuiInputBase-multiline)": {
            boxShadow: ELEVATION_2,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: PRIMARY_COLOR,
          },
        },

        "&.Mui-disabled": {
          color: TEXT_DISABLED_COLOR,
          borderColor: DISABLED_SECONDARY_COLOR,

          "&.MuiInputBase-adornedStart svg": {
            color: DISABLED_PRIMARY_COLOR,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: DISABLED_SECONDARY_COLOR,
          },
        },

        variants: [
          {
            props: { size: "small" },
            style: {
              borderRadius: BORDER_RADIUS_SMALL,

              input: {
                padding: "0.75rem", // 12px
                height: INPUT_SIZE_SMALL,
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              borderRadius: BORDER_RADIUS_SMALL,

              input: {
                padding: "1rem", // 16px
                height: INPUT_SIZE_MEDIUM,
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              borderRadius: BORDER_RADIUS_MEDIUM,
              fontSize: BODY_1_FONT_SIZE,

              input: {
                padding: "1.5rem", // 24px
                height: INPUT_SIZE_LARGE,
              },
            },
          },
        ],
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS_LARGE,
        border: "none",
        padding: "0.25rem",
        justifyContent: "space-between",
        width: "-webkit-fill-available",
        fontSize: BODY_2_FONT_SIZE,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        padding: "0.75rem 1rem",
        borderRadius: `${BORDER_RADIUS_XLARGE} !important`,
        border: `1px solid ${BORDER_PRIMARY_COLOR} !important`,
        backgroundColor: SURFACE_SECONDARY_COLOR,
        color: PRIMARY_COLOR,

        "&.Mui-selected": {
          border: `1px solid ${PRIMARY_COLOR} !important`,
          backgroundColor: SURFACE_SECONDARY_COLOR,

          "&:hover": {
            backgroundColor: SURFACE_SECONDARY_COLOR,
          },
        },

        "&:hover": {
          border: `1px solid ${PRIMARY_COLOR} !important`,
          backgroundColor: SURFACE_SECONDARY_COLOR,
        },

        "&.Mui-disabled": {
          color: DISABLED_PRIMARY_COLOR,
          border: `1px solid ${DISABLED_SECONDARY_COLOR} !important`,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        backgroundColor: BACKGROUND_PRIMARY_COLOR,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperFullScreen: {
        // See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        // See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        boxSizing: "content-box",
        marginTop: "auto",
        paddingTop: 16,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24,
        justifyContent: "space-between",
        height: 48,
        backgroundColor: BACKGROUND_TERTIARY_COLOR,
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        width: 48,
        maxWidth: 48,
        minWidth: "unset",
        height: 48,
        maxHeight: 48,
        minHeight: "unset",
        borderRadius: BORDER_RADIUS_SMALL,

        "&.Mui-selected": {
          color: TEXT_INVERT_PRIMARY_COLOR,
          backgroundColor: PRIMARY_COLOR,
        },

        "& svg": {
          height: 28,
          width: 28,
        },
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      root: {},
      readOnly: {
        color: PRIMARY_COLOR,

        "& .MuiRating-iconEmpty": {
          opacity: 1,
        },
      },
      sizeSmall: {
        gap: 4,
      },
      sizeMedium: {
        gap: 8,
      },
      sizeLarge: {
        gap: 8,
      },
      label: {
        color: PRIMARY_COLOR,
      },
      iconEmpty: {
        opacity: 0.2,
      },
      icon: {
        color: PRIMARY_COLOR,

        "& svg": {
          flexShrink: 0,
        },
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      colorInfo: UNREAD_CHAT_NOTIFICATION_BADGE_COLOR,
    },
  },
  // TODO: ListItem probably needs our own component set, but we need some more details from Metalab before proceeding
  // For now this is simply styled to match facility details in shift bottom sheet
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        padding: "1.5rem 0",

        "&.Mui-disabled": {
          opacity: 1,
          color: DISABLED_PRIMARY_COLOR,
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: "1.5rem 0",
        borderRadius: 0,

        "&:hover": {
          backgroundColor: BACKGROUND_SECONDARY_COLOR,
        },

        "&.Mui-disabled": {
          opacity: 1,
          color: DISABLED_PRIMARY_COLOR,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: "inherit",
        minWidth: "2rem",
        alignSelf: "flex-start",
        ".Mui-disabled &": {
          color: DISABLED_PRIMARY_COLOR,
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        margin: 0,
      },
      primary: {
        fontSize: H5_FONT_SIZE,
        lineHeight: H5_LINE_HEIGHT,
        color: TEXT_PRIMARY_COLOR,
        fontWeight: 500,
      },
      secondary: {
        fontSize: BODY_2_FONT_SIZE,
        lineHeight: BODY_2_LINE_HEIGHT,
        color: TEXT_PRIMARY_COLOR,
        marginTop: SPACINGS[1],
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: BORDER_SUBTLE_COLOR,
      },
    },
  },
};
/* eslint-enable max-lines */
